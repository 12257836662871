import { AuthOptions, getServerSession } from "next-auth";
import CredentialsProvider from "next-auth/providers/credentials";
import GoogleProvider from "next-auth/providers/google";
import axios from "axios";

const API_URL = process.env.NEXT_PUBLIC_API_URL;
const authOptions: AuthOptions = {
  providers: [
    CredentialsProvider({
      id: "roave-signin",
      name: "Credentials",
      type: "credentials",
      credentials: {
        email: { label: "Email", type: "email" },
        password: { label: "Password", type: "password" },
      },

      async authorize(credentials: any, req: any) {
        // console.clear();
        // console.log("Inside auth roave-signin:", credentials);
        const IP = req?.headers['cf-connecting-ip'] || (req?.headers['x-forwarded-for']?.split(',')[0] != "(null)" || req?.headers['x-forwarded-for']?.split(',')[1]);
        // console.log("Client IP (X-Forwarded-For):", IP, req?.headers);
       
        const forwardedFor = IP != true ? IP?.trim() : IP || req?.socket?.remoteAddress || 'unknown';
        // console.log("Client IP (X-Forwarded-For):", IP, req?.headers);

        const header =  {
          "Content-Type": "application/json",
          "X-Forwarded-For": forwardedFor,
          "X-IP": forwardedFor,
        }
        try {
          const response = await fetch(API_URL + "/v1/auth/user/login", {
            method: "POST",
            headers: header,
            body: JSON.stringify({
              password: credentials?.password,
              email: credentials?.email,
              login_via: credentials?.login_via,
            }),
          });
          const { status, message, data } = await response.json();
          console.log("API Response:", status, message, data );
          if (status === 200 && data) {
            return data;
          } else {
            console.log(
              "Authentication data not found  In Login:",
              status,
              message,
              data
            );
            throw new Error(message);
          }
        } catch (error: any) {
          console.error("Authorization exception In Login :", error?.message);
          throw new Error(error);
          return error;
        }
      },
    }),
    CredentialsProvider({
      id: "roave-signup",
      name: "Credentials",
      type: "credentials",
      credentials: {
        email: { label: "Email", type: "email" },
        password: { label: "Password", type: "password" },
      },
      async authorize(credentials: any, req: any) {
        console.clear();
        console.log("Inside auth roave-signup:", credentials);
        const IP = req?.headers['cf-connecting-ip'] || (req?.headers['x-forwarded-for']?.split(',')[0] != "(null)" || req?.headers['x-forwarded-for']?.split(',')[1]);
        const forwardedFor =  IP != true ? IP?.trim() : IP || req?.socket?.remoteAddress || 'unknown';
        console.log("Client IP (X-Forwarded-For):", IP, req?.headers);
        try {
          const response = await fetch(API_URL + "/v1/auth/user/signup", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-Forwarded-For": forwardedFor,
            },
            body: JSON.stringify({
              first_name: credentials?.first_name,
              last_name: credentials?.last_name,
              password: credentials?.password,
              email: credentials?.email,
              registration_via: credentials?.registration_via,
              referral_code: credentials?.referral_code,
            }),
          });
          const result = await response.json();
          const { status, message, data } = result;

          if (status === 200 && data != null) {
            console.log("status auth roave-signup:", data);
            return data;
          } else {
            console.log(
              "Authentication Error No result:",
              status,
              message,
              data
            );
            throw new Error(message);
          }
        } catch (error: any) {
          console.error("Authorization catch:", error);
          throw new Error(error);
          return error;
        }
      },
    }),
    GoogleProvider({
      clientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID! as string,
      clientSecret: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_SECRET! as string,
      authorization: {
        params: {
          prompt: "consent",
          access_type: "offline",
          response_type: "code",
          redirect_uri: process.env.NEXT_PUBLIC_BASE_URL + "/api/auth/callback/google",
        },
      }
    }),
    // Google One Tap Credentials Provider
    CredentialsProvider({
      id: "googleonetap", // ID for Google One Tap
      name: "Google One Tap",
      type: "credentials",
      credentials: {
        credential: { label: "Credential", type: "text" },
      },
      async authorize(credentials, req: any) {
        console.log("Google One Tap authorize", credentials);
        const forwardedFor = req?.headers['cf-connecting-ip'] || req?.headers['x-forwarded-for'] || req?.socket?.remoteAddress || 'unknown';

        console.log("Client IP (X-Forwarded-For):", forwardedFor);
        try {
          const res = await axios.post(
            "https://devapi.envoyhealth.io/api/v1/auth/login",
            {
              credential: credentials?.credential,
            }
          );
          console.log("Login res:", res);
          const user = res?.data;
          if (res?.data?.status === 200 && user) {
            return user;
          } else {
            console.log("Login Error:", user);
            return null;
          }
        } catch (error: any) {
          console.error("Login error:", error?.message);
          return null;
        }
      },
    }),
  ],
  session: {
    strategy: "jwt",
  },
  callbacks: {
    async jwt({
      token,
      user,
      account,
      trigger,
      session
    }: any) {
      console.clear();
     

      if(trigger == "update") {
        console.log("Inside JWT Callbacks trigger:", trigger, session);
        if (session?.user) {
          Object.keys(session?.user).forEach((key) => {
            token[key] = session?.user[key];
          });
          if(session?.user?.sub || session?.user?.google_id){ 
            token.accessToken = account?.access_token;
            token.auth_token =  session?.user?.auth_token;
            token.customData = {
              auth_token: session?.user?.auth_token,
              email: session?.user?.email,
              first_name:  session?.user?.first_name,
              last_name:  session?.user?.last_name,
              currency:   session?.user?.currency,
              country_code:   session?.user?.country_code,
            }    
          }
        }
        return token;
      }
     

      // console.log("Inside JWT Callbacks token:", token);
      // console.log("Inside JWT Callbacks user", user);
      // console.log("Inside JWT Callbacks account:", account);
      if (account?.provider == "google" && user?.customData) {
       
        token.accessToken = account?.access_token;
        token.auth_token = user?.customData?.access_token;
        token.customData = user?.customData;
      }
      if (
        account?.type == "credentials" &&
        (account?.provider == "roave-signup" ||
          account?.provider == "roave-signin")
      ) {
        if (user) {
          Object.keys(user).forEach((key) => {
            token[key] = user[key];
          });
        }
      }
      return token;
    },
    async session({ session, token }: { session: any; token: any }) {
        // console.log("Inside Session Session:", session, token);
        // console.log("Inside Session Token:",  token);
      if (token?.sub) {
        console.log("Inside sub:" , token);

        Object.keys(token).forEach((key) => {
          session["user"][key] = token[key];
        });
        session["user"]._id = token?.customData?._id;
        session["user"].first_name = token?.customData?.first_name;
        session["user"].last_name = token?.customData?.last_name;
        if(token?.customData?.auth_token){
          session["user"].auth_token = token?.customData?.auth_token; 
        }
        session["user"].currency = token?.customData?.currency; 
        session["user"].country_code = token?.customData?.country_code; 
        session["user"].title = token?.customData?.title || ""; 
        session["user"].phone_number = token?.customData?.phone_number || ""; 
        session["user"].customData = token?.customData;
      } else {
        if (token) {
          Object.keys(token).forEach((key) => {
            session["user"][key] = token[key];
          });
        }
      }
      session.provider = token?.provider;

      return session;
    },
  },
  pages: {
    signIn: "/login",
  },
  debug: true, //process.env.NODE_ENV === "development",
  secret: process.env.NEXTAUTH_SECRET,
  logger: {
    error(code, ...message) {
      console.log(1, code, message);
    },
    warn(code, ...message) {
      // console.log(2, code, message);
    },
    debug(code, ...message) {
      // console.log(3, code, message);
    },
  },
};

/**
 * Helper function to get the session on the server without having to import the authOptions object every single time
 * @returns The session object or null
 */
const getSession = () => getServerSession(authOptions);

export { authOptions, getSession };
